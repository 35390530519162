<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">Clients</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link
          to="/clients/create">
          <button class="button expanded">
            Add Client
          </button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(client, index) in clients"
              :key="index">
              <td>{{ client.name }}</td>
              <td>
                <router-link :to="`/clients/view/${client.id}`">
                  <button class="button small mr-2">View</button>
                </router-link>
                <router-link :to="`/clients/edit/${client.id}`">
                  <button class="button small mr-2">Edit</button>
                </router-link>
                <router-link :to="`/job-numbers/create/${client.id}`">
                  <button class="button small">Add Job</button>
                </router-link>
                &nbsp;
                <router-link :to="`/job-numbers/index/${client.id}`">
                  <button class="button small">View Jobs</button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ClientsIndex',
  data() {
    return {
      clients: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    fetchClients() {
      axios.get(`/clients/fetchAll.json?token=${this.token}`)
        .then((response) => {
          this.clients = response.data.clients;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchClients();
    }, 100);
  },
};
</script>
